<template>
    <mercur-card class="full-height-layout fill mx-4 mb-3">
        <div>
            <h2 class="font-weight-normal" v-if="cleanLocationData">Editing holidays for {{cleanLocationData.locationName}}</h2>
            <p>Select the holidays you would like to apply to this location</p>
        </div>
        <ag-grid-vue
            class="ag-grid fill ag-theme-material border"
            :columnDefs="columnDefs"
            :animateRows="true"
            rowModelType="clientSide"
            :rowData="holidays"
            :pagination="true"
            :enableRangeSelection="false"
            :suppressCellSelection="true"
            @grid-ready="onGridReady"
            :detailRowHeight="400"
            @cell-value-changed="onCellValueChanged"
        ></ag-grid-vue>
        <portal to="actionbar">
            <transition name="slide">
                <div v-if="dirty" class="card-padding">
                    <mercur-button class="btn btn-raised btn-yellow text-uppercase" @click="saveLocationHolidays" :disabled="loading">Save changes</mercur-button>
                </div>
            </transition>
        </portal>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'
import LocationBase from '@/views/supplier/locations/LocationBase'

export default {
    name: 'SupplierEditLocationHolidays',
    mixins: [ LocationBase ],
    components: { AgGridVue },
    data () {
        return {
            shouldGetServiceLevels: false,
            selectedHolidays: [],
            loading: false,
            dirty: false,
        }
    },
    beforeMount () {
        this.columnDefs = [
            {
                headerName: 'Selected',
                field: 'selected',
                width: 90,
                cellRendererFramework: 'CheckboxCell',
                headerComponentFramework: 'CheckboxCell',
                headerComponentParams: () => {
                    return {
                        disabled: this.loading,
                        setValue: (newValue) => {
                            if (!newValue) {
                                this.selectedHolidays = []
                                return
                            }

                            this.selectedHolidays = this.holidays.flatMap(holiday => holiday.holidayId)
                        },
                        getValue: () => {
                            if (this.holidays === null) {
                                return false
                            }
                            return !this.holidays.some(holiday => !this.selectedHolidays.includes(holiday.holidayId))
                        },
                    }
                },
            },
            {
                headerName: 'Name',
                field: 'holidayName',
                sortable: true,
            },
            {
                headerName: 'Start',
                field: 'holidayFrom',
                sortable: true,
                valueFormatter: ({ value }) => {
                    return this.$options.filters.asDatetime(value)
                },
            },
            {
                headerName: 'End',
                field: 'holidayTo',
                sortable: true,
                valueFormatter: ({ value }) => {
                    return this.$options.filters.asDatetime(value)
                },
            },
            {
                headerName: 'Yearly',
                field: 'yearly',
                sortable: true,
                valueFormatter: ({ value }) => {
                    return value ? 'yes' : 'no'
                },
            },
        ]
    },
    methods: {
        onGridReady (params) {
            this.gridApi = params.api
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit()
            }, 100)
            window.addEventListener('resize', () => {
                this.gridApi.sizeColumnsToFit()
            })
        },
        onCellValueChanged (params) {
            this.dirty = true
            this.selectedHolidays = this.holidays.filter(holiday => holiday.selected).flatMap(holiday => holiday.holidayId).sort()
        },
        saveLocationHolidays () {
            const url = CONFIG.API.ROUTES.SUPPLIERS.LOCATION_SET_HOLIDAYS.replace('{supplierId}', this.supplierId).replace('{locationId}', this.$route.params.locationId)
            this.loading = true
            this.addJob(url)
            this.$api.post(url, this.selectedHolidays).then(({ data }) => {
                this.dirty = false
                this.$root.$emit('notification:global', {
                    message: `Location holidays were updated`,
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Setting location holidays failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },
    },
    computed: {
        holidays () {
            const holidays = this.$store.state.holidays.holidays[this.supplierId]

            if (!holidays) {
                return null
            }

            return holidays.map(holiday => {
                return {
                    ...holiday,
                    selected: this.selectedHolidays.includes(holiday.holidayId),
                }
            })
        },
    },

    created () {
        this.$store.dispatch('holidays/fetchSupplierHolidays', this.supplierId)
        this.$store.dispatch('holidays/fetchLocationHolidays', {
            supplierId: this.supplierId,
            locationId: this.$route.params.locationId,
        }).then(data => {
            this.selectedHolidays = [
                ...this.selectedHolidays,
                ...data.flatMap(holiday => holiday.holidayId),
            ]
        })
    },
}
</script>

<style scoped>

</style>
